@import "../defaults";

.mod_news_overview {
    padding: 0 0 45px;
    font-size: 20px;
    line-height: 1.1;

    &.loading {
        background: url('../../images/ajax-loader.gif') 50% 50% no-repeat;

        .list_items {
            opacity: 0.25;
        }
    }

    .inside {
        @extend .primary-header;
    }

    .list_items {
        width: 100%;

        li {
            display: flex;
            position: relative;
            padding: 10px 0;

            a {
                display: block;
                color: $default-text-color;

                &:hover {
                    text-decoration: none;
                }
            }

            time {
                width: 100px;
                flex-shrink: 0;
                font: italic 30px/1 $font-serif;
                color: $level4-text-color;
            }
        }
    }

    .ce_hyperlink {
        position: relative;
        margin: 0;
        padding: 15px 0 3px;
        text-align: center;
        font-size: 14px;
    }

    .most_read {
        display: none;
        padding-bottom: 40px;

        p.message {
            margin: 0 12px;

            &.empty {
                padding: 1em;
                font-size: 16px;
                font-style: italic
            }
        }

        ol {
            counter-reset: li;
        }

        li {
            padding: 20px 0 20px 100px;

            &:before {
                content:counter(li);
                counter-increment:li;

                position:absolute;
                left: 0;
                top: 30px;
                width: 80px;
                font: italic 72px/22px $font-serif;
                color: $level2-text-color;
                text-align: center;
            }
        }

        a {
            min-height: 36px;
        }
    }

    .radio_container {
        line-height: 24px;
        font-weight: $font-medium;
        color: $body-color;

        input {
            position: absolute;
            visibility: hidden;
        }

        label {
            display: block;
            text-transform: uppercase;
            text-align: center;
            cursor: pointer;
        }

        &.views {
            display: none;
            justify-content: space-between;
            gap: 12px;
            margin: 10px 0 20px;
            line-height: 30px;

            span {
                position: relative;
                width: 50%;
                background-color: $level4-text-color;
                font-size: 13px;

                &.active {
                    background-color: $primary-color;

                    &:after {
                        content: "";
                        position: absolute;
                        width: 0;
                        height: 0;
                        bottom: -5px;
                        left: 50%;
                        margin-left: -3px;
                        border: 4px solid transparent;
                        border-top-color: #fff;
                        border-left-color: #fff;
                        transform: rotateZ(45deg);
                    }
                }
            }
        }

        &.period {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            gap: 8px;

            span {
                flex-grow: 1;
                position: relative;
                background-color: $level4-text-color;
                font-size: 12px;
                border-bottom: 2px solid transparent;

                &.active {
                    margin-bottom: 0;
                    background-color: transparent;
                    color: $primary-color;
                    border-bottom-color: $primary-color;

                    &:after {
                        content: "";
                        position: absolute;
                        width: 0;
                        height: 0;
                        bottom: -4px;
                        left: 50%;
                        margin-left: -2px;
                        border: 3px solid transparent;
                        border-top-color: $primary-color;
                        border-left-color: $primary-color;
                        transform: rotateZ(45deg);
                    }
                }
            }
        }
    }
}
