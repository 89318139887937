@import "../defaults";

.mod_contest {
    h1 {
        margin-bottom: 20px;
        font-size: 36px;
        line-height: 1.2em;
        text-align: center;
        text-transform: uppercase;
    }

    input[type="hidden"] + .ce_image {
        margin-top: 0;
    }
}
