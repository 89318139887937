@import "defaults";

.layout-homepage {
    #main > .inside {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        > * {
            flex-grow: 1;
        }
    }

    .mod_news_overview {
        padding-left: $gutter-homepage;
        padding-right: $gutter-homepage;

        @include media(medium) {
            width: span(6 of 12);
        }

        @include media(xlarge) {
            width: span(5 of 12);
            margin-top: 40px;
        }
    }
}

.mod_news_homecluster {
    @include span(100%, true);

    &__box {
        display: flex;
        margin-top: 18px;
        background: #eaeaed;
    }

    &__title {
        display: flex;
        align-items: center;
        padding: 14px 20px;
        background: #fce830;
        font-weight: $font-bold;
        text-transform: uppercase;
    }

    &__text {
        padding: 14px 20px;
        flex-grow: 1;
    }

    &__headline {
        margin: 0;
        flex-grow: 1;
        font-weight: $font-medium;
    }

    &__link {
        flex-shrink: 0;
        align-self: center;
        margin: .5em 0 0;
    }

    &__title,
    &__headline {
        a {
            color: inherit;

            &:hover {
                text-decoration: none;
            }
        }
    }

    @include media(medium) {
        &__text {
            display: flex;
        }

        &__link {
            margin-top: 0;
        }
    }
}

.mod_news_featured {
    > header {
        @extend .primary-header;
        margin-left: $gutter-homepage;
        margin-right: $gutter-homepage;
        margin-bottom: 35px;
    }

    > .inside {
        display: flex;
        flex-wrap: wrap;
    }
}

.mod_news_people {
    clear: both;
    margin-bottom: 70px;
    padding: 0 $gutter-homepage;

    header {
        @extend .primary-header;
    }

    article {
        position: relative;
        overflow: hidden;
        padding-bottom: 30px;

        a {
            display: flex;
            color: $default-text-color;
            text-decoration: none;
        }

        .image_container {
            flex-shrink: 0;
            width: 125px;
            padding-right: 25px;
        }

        p {
            font-size: 20px;
            line-height: 1.1em;
            margin: 0;
        }

        time {
            display: block;
            font-size: 13px;
            line-height: 1;
            margin: 10px 0 0;
        }
    }

    @include media(large) {
        article {
            .image_container {
                //noinspection CssInvalidPropertyValue
                width: calc(span(2 of 5) + 19px); // 19px + 25px padding-right = 44px gutter
            }

            .text {
                //noinspection CssInvalidPropertyValue
                width: calc(span(3 of 5) - 22px);
            }
        }
    }

    @include media(xlarge) {
        width: span(5 of 12);
    }
}

.mod_contest_list {
    padding-left: $gutter-homepage;
    padding-right: $gutter-homepage;

    > header {
        @extend .primary-header;
    }

    > .inside {
        margin-bottom: 70px;
    }

    .contest {
        margin-bottom: 35px;

        .image_container {
            margin-bottom: 1em;
        }

        h3 {
            font-size: 20px;
            font-weight: $font-medium;
        }

        p {
            margin: 10px 0;
        }

        a {
            padding-left: 1.2em;
            background: url("../images/hyperlink-primary.svg") left center no-repeat;
            background-size: 0.8em 0.8em;
        }
    }

    @include media(medium) {
        width: span(4 of 12);
    }
}

.mod_news_ticker {
    margin-bottom: 70px;
    @include span(100%, true);

    .inside {
        @extend .primary-header;
        padding-left: 10px;
        padding-right: 10px;
        border-top: 1px solid #4d4d4d;
        border-bottom: 1px solid #4d4d4d;
        background: #eeeeee;
    }

    article {
        margin: 15px 0;
    }

    a {
        color: $default-text-color;
    }

    h1 strong {
        font-weight: $font-regular;
    }

    .section {
        font-size: 14px;
        font-weight: $font-medium;
    }

    time {
        font-size: 14px;
        color: inherit;

        &:before {
            content: " - ";
        }
    }

    @include media(medium) {
        width: span(6 of 12);
    }

    @include media(large) {
        width: span(3 of 12);
    }
}

.switzerland-news {
    position: relative;
    overflow: hidden;
    margin-bottom: 70px;
    @include span(100%, true);

    .inside {
        padding: 180px 10px 30px;

    }

    .image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #e85f53 url(../images/switzerland--cow.svg) 50% 20px no-repeat;
        background-size: 480px;
        z-index: -1;

        &.food {
            background-image: url("../images/switzerland--food.svg");
        }

        &.hiking {
            background-image: url("../images/switzerland--hiking.svg");
        }

        &.mountains {
            background-image: url("../images/switzerland--mountains.svg");
        }

        &.ski {
            background-image: url("../images/switzerland--ski.svg");
        }

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: -30%;
            left: 0;
            background: radial-gradient(circle, rgba(255,255,255,0) 50%, #e85f53 75%);
            width: 100%;
            height: 130%;
            z-index: 10;
        }
    }

    p {
        position: relative;
        font-size: 20px;
        line-height: 1.1;
        color: #ffd478;
        font-weight: $font-bold;

        &:before {
            content: "";
            position: absolute;
            top: -15px;
            left: 0;
            height: 5px;
            width: 40px;
            background: #fff;
        }
    }

    strong {
        display: block;
        font-size: 30px;
        color: #fff;
        font-weight: inherit;
        text-transform: uppercase;
    }

    a {
        color: #fff;
        font-weight: $font-medium;
        text-transform: uppercase;

        &:after {
            content: " >";
        }
    }

    @include media(medium) {
        .inside {
            padding: 20px 20px 20px 400px;
        }

        .image {
            background-position: -50px 10px;

            &:before {
                content: none;
            }
        }
    }
}

.mod_news_homespecial {
    width: 100%;
    margin-bottom: 20px;

    > header {
        @extend .primary-header;
        margin-left: $gutter-homepage;
        margin-right: $gutter-homepage;
        margin-bottom: 35px;
    }

    .box {
        display: block;
        position: relative;
        @include span(100%, true);
        margin-bottom: 35px;

        &:before {
            content: "";
            position: absolute;
            z-index: 5;
            top: 0;
            right: $gutter-homepage;
            bottom: 0;
            left: $gutter-homepage;
            background: #000;
            background: rgba(0, 0, 0, 0.4);
        }

        .title {
            position: absolute;
            z-index: 10;
            left: 50px;
            right: 50px;
            top: 50%;
            transform: translateY(-50%);
            text-align: center;
            color: #fff;

            h3 {
                font: italic 23px/1 $font-serif;
                margin-bottom: 15px;
            }

            p {
                margin: 0;
                font-size: 36px;
                line-height: 1;
                text-transform: uppercase;
            }
        }
    }

    @include media(large) {
        &.single {
            width: span(8 of 12);
            max-width: calc(100vw - 340px); // always leave room for ad banner

            + .ad_zone {
                min-width: 300px;
                padding-left: $gutter-homepage;
                padding-right: $gutter-homepage;
            }
        }

        &.double {
            .inside {
                display: flex;
            }
        }
    }
}

.mod_news_homesection {
    > header {
        @extend .primary-header;
        margin-left: $gutter-homepage;
        margin-right: $gutter-homepage;
        margin-bottom: 35px;
    }

    .sections {
        display: flex;
        flex-wrap: wrap;
    }

    .section {
        padding: 0 $gutter-homepage;
        margin-bottom: 35px;
    }

    h3 {
        position: relative;
        padding: 8px 0 20px;
        font-weight: $font-bold;
        text-transform: uppercase;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            height: 3px;
            width: 40px;
            background: $primary-color;
        }

        a:hover {
            text-decoration: none;
        }
    }

    article {
        float: none;
        width: 100%;
        padding: 0 0 20px !important;

        h1 {
            font-size: 24px;
            margin-bottom: 8px;
        }

        .teaser {
            display: none;
        }

        &.image_secondary .inside {
            border-top: none;
            padding-top: 0;
        }

        &.image_none {
            border-top: 1px dotted $border-color;

            h1 {
                font-size: 20px;
            }
        }
    }

    @include media(medium) {
        .section {
            @include span(6 of 12, true);
        }
    }

    @include media(xlarge) {
        .section {
            @include span(4 of 12, true);
        }
    }
}

.mod_video_home {
    > header {
        @extend .primary-header;
        margin-left: $gutter-homepage;
        margin-right: $gutter-homepage;
        margin-bottom: 35px;
    }

    @include media(medium) {
        > .image_video_home {
            text-align: center;

            h1 {
                font-size: 36px;
            }
        }

        > .items {
            display: flex;
            flex-wrap: wrap;
            float: left;
            width: 50%;
        }
    }
}
