@import "defaults";

input.text,
textarea,
select {
    display: block;
    width: 100%;
    height: 37px;
    margin: 0;
    padding: 8px;
    line-height: 21px;
    border: 1px solid $border-color;
    border-radius: 2px;
}

.widget-search {
    position: relative;

    input {
        padding-right: 40px;
    }

    &:after {
        position: absolute;
        top: 10px;
        right: 13px;
        width: 18px;
        height: 18px;
        background: url(../images/search.svg) no-repeat right center;
        background-size: 18px 18px;
        content: "";
    }
}

.widget-submit button,
.fineuploader-wrapper div.qq-upload-button,
.fineuploader-wrapper .qq-upload-drop-area,
.ce_hyperlink a.hyperlink_btn,
.hyperlink_btn {
    @include on-primary-background;
    display: inline-block;
    width: auto;
    margin: 0;
    padding: 0 15px;
    font-size: 13px;
    line-height: 34px;
    font-weight: $font-medium;
    text-align: center;
    text-transform: uppercase;
    border: none;
    border-radius: 2px;
    white-space: nowrap;

    &:disabled {
        background-color: $border-color;
    }

    &.loading {
        background: linear-gradient(270deg, $primary-color, #8daedc);
        background-size: 400% 400%;
        animation: LoadingButton 1.5s linear infinite;
    }

    &.qq-upload-button-hover {
        background: $primary-color;
    }
}

@keyframes LoadingButton {
    0%{background-position:0 50%}
    50%{background-position:100% 50%}
    100%{background-position:0 50%}
}

textarea {
    padding-right: 40px;
    height: 100px;
    background-size: 18px 18px
}

select {
    position: relative;
    overflow: hidden;
    height: 35px;
    padding: 0 35px 0 8px;
    font-size: 15px;
    line-height: 35px;
    text-overflow: ellipsis;
    background: #fff url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSItMTk3IDQ3OS45IDI0IDEyIj48c3R5bGU+LnN0MHtmaWxsOiNjZGNkY2R9PC9zdHlsZT48cGF0aCBjbGFzcz0ic3QwIiBkPSJNLTE4OSA0ODMuOWgtOGw0IDR6Ii8+PC9zdmc+) right center no-repeat;
    background-size: 24px 12px;
    border: 1px solid #dddde1;
    border-radius: 2px;
    -webkit-appearance: none;

    &:disabled {
        opacity: 0.5;
    }
}

.formbody {
    overflow: hidden;
    margin: 1em 0;
    font-size: 16px;
    line-height: 21px;

    .widget {
        position: relative;
        clear: both;
        margin: 10px 0;

        > label {
            display: block;
            padding-top: 8px;
            padding-bottom: 8px;
        }

        span.mandatory {
            margin-left: 4px;
        }
    }

    .widget-radio,
    .widget-checkbox {
        margin: 18px 0;

        legend {
            padding-bottom: 8px;
        }
    }

    fieldset {
        margin: 10px 0;

        > .widget:first-child {
            margin-top: 0;
        }

        > .widget:last-child {
            margin-bottom: 0;
        }

        > .widget-radio,
        > .widget-checkbox {
            &:first-child {
                margin-top: 8px;
            }

            &:last-child {
                margin-bottom: 8px;
            }

            > fieldset {
                margin: 0;
            }
        }
    }

    .radio_container,
    .checkbox_container {
        > span {
            display: block;
            clear: both;
        }

        &.inline > span {
            display: inline-block;
            padding-right: 30px;
            white-space: nowrap;
        }

        span input {
            float: left;
        }

        span label {
            display: block;
            margin-left: 25px;
        }
    }

    .widget-fineuploader {
        overflow: hidden;

        .fineuploader-wrapper {
            overflow: hidden;
        }

        .fineuploader-item {
            margin-bottom: $gutter;

            &.image {
                display: flex;

                .image {
                    margin-right: $gutter;
                }
            }
        }

        .upload-container {
            margin-top: $gutter;

            .qq-total-progress-bar-container {
                margin: 0 0 10px;
                border: 2px solid $primary-color;
                background: $body-color;
            }

            .qq-total-progress-bar {
                height: 10px;
                margin: 0;
                border-radius: 0;
                background: $primary-color;
            }

            .qq-upload-list {
                li {
                    margin-top: 10px;
                }

                .qq-progress-bar-container-selector {
                    display: none;
                }
            }
        }
    }

    .widget-submit {
        text-align: right;

        button {
            margin-top: 30px;
        }
    }

    p.error {
        margin: 0 0 5px 0;
        padding: 3px 8px;
        background: $error-color;
        border-radius: 2px;
    }

    @include media(medium) {
        font-size: 15px;

        .widget:not(.vertical) {
            > label {
                float: left;
                width: span(3 of 7);
                padding-right: $gutter * 2;
                text-align: right;
            }

            p.error {
                margin-top: 15px;
            }

            .radio_container,
            .checkbox_container {
                position: relative;
                padding-left: span(3 of 7);

                p.error {
                    width: auto;
                    margin-left: 0;
                    margin-top: 0;
                }

                legend {
                    position: absolute;
                    left: 0;
                    width: span(3 of 7);
                    padding-right: $gutter * 2;
                    padding-bottom: 0;
                    text-align: right;
                }
            }

            input.text,
            textarea,
            select,
            p.error,
            .upload_container,
            .selector_container {
                width: span(4 of 7);
                margin-left: span(3 of 7);
            }

            label + input.text,
            label + textarea {
                margin-left: 0;
            }
        }
    }

    @include media(large) {
        .widget:not(.vertical) {
            > label {
                width: span(2 of 7);
            }

            .radio_container,
            .checkbox_container {
                padding-left: span(2 of 7);

                legend {
                    width: span(2 of 7);
                }
            }

            input.text,
            textarea,
            select,
            p.error,
            .upload_container,
            .selector_container {
                width: span(5 of 7);
                margin-left: span(2 of 7);
            }
        }
    }

    @include media(xlarge) {
        .widget {
            width: span(7 of 8);
        }

        .widget-explanation {
            width: auto;
            padding: 0;
        }
    }
}
