.mod_counter_offer {
    margin-bottom: 45px;

    .ad_zone.ad_empty {
        display: none !important;
    }

    .result {
        margin: 2em 0;
        text-align: center;

        .filter-reset {
            margin: 0;
            padding: 0;
            font-size: inherit;
            color: $primary-color;
            text-decoration: underline;
            background: none;
            border: none;
            border-radius: 0;
            cursor: pointer;
            -webkit-appearance: none;
        }
    }

    .filters {
        background: #f7f7f7;
        margin: 2em 0 3em;
        padding: 10px 0;

        .filter {
            position: relative;
            padding: 5px 15px;

            .filter-trigger {
                overflow: hidden;
                padding: 5px 10px 5px 27px;
                text-overflow: ellipsis;
                white-space: nowrap;
                background: #fff;
                border: 1px solid #dddde1;
                border-radius: 2px;
                background-position: 8px center;
                background-repeat: no-repeat;
                background-size: 12px 12px;
                cursor: pointer;

                &--destination {
                    background-image: url('../../images/counteroffer-dropdown-destination.svg');
                }
                &--offer {
                    background-image: url('../../images/counteroffer-dropdown-offer.svg');
                }
                &--target {
                    background-image: url('../../images/counteroffer-dropdown-target.svg');
                }
            }

            .filter-dropdown {
                display: none;
                position: absolute;
                flex-wrap: wrap;
                left: 0;
                top: calc(100% + 8px);
                margin: 0 15px;
                padding: 20px 30px;
                background: $body-color;
                -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.19);
                -moz-box-shadow:    0 2px 5px 0 rgba(0, 0, 0, 0.19);
                box-shadow:         0 2px 5px 0 rgba(0, 0, 0, 0.19);
                z-index: 1;

                &:before {
                    position: absolute;
                    top: -8px;
                    left: 30px;
                    width: 16px;
                    height: 16px;
                    background: #fff;
                    transform: rotate(45deg);
                    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.19);
                    -moz-box-shadow:    0 2px 5px 0 rgba(0, 0, 0, 0.19);
                    box-shadow:         0 2px 5px 0 rgba(0, 0, 0, 0.19);
                    content: "";
                    z-index: -1;
                }

                &:after {
                    position: absolute;
                    top: 0;
                    left: 20px;
                    width: 40px;
                    height: 20px;
                    background: #fff;
                    content: "";
                    z-index: 5;
                }

                label {
                    display: block;
                    position: relative;
                    cursor: pointer;
                    width: 100%;
                    padding: 0 12px 0 26px;
                    margin: 2px -12px;

                    > input {
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }

                .filter-group {
                    width: 100%;
                    margin: 4px 0;

                    .filter-group-title {
                        position: relative;
                        padding-right: 40px;
                        font-weight: $font-medium;
                        border-bottom: 1px solid $border-color;
                    }

                    .filter-group-content {
                        display: none;
                        flex-wrap: wrap;
                        padding: 10px 0 10px 20px;
                    }

                    .filter-group-trigger {
                        display: block;
                        width: 24px;
                        height: 20px;
                        position: absolute;
                        top: 0;
                        right: 0;
                        cursor: pointer;
                        background: #fff url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSItMTk3IDQ3OS45IDI0IDEyIj48c3R5bGU+LnN0MHtmaWxsOiNjZGNkY2R9PC9zdHlsZT48cGF0aCBjbGFzcz0ic3QwIiBkPSJNLTE4OSA0ODMuOWgtOGw0IDR6Ii8+PC9zdmc+) center center no-repeat;
                        background-size: 24px 12px;
                        z-index: 1;
                    }

                    &.active {
                        .filter-group-trigger {
                            transform: rotateX(180deg);
                        }

                        .filter-group-content {
                            display: flex;
                        }
                    }
                }

                .widget-submit {
                    width: 100%;
                    margin: 30px 0 0;
                    text-align: right;
                }
            }

            &.active .filter-dropdown {
                display: flex;
            }

            @include media(medium) {
                &.destination {
                    .filter-dropdown {
                        width: 750px;
                        max-width: 90vw;
                        padding-left: 18px;
                        padding-right: 18px;
                    }

                    .filter-group {
                        width: 50%;
                        padding: 0 12px;
                    }
                }
            }
        }
    }

    .items {
        display: flex;
        flex-wrap: wrap;
        margin: 0 $gutter * -1;
    }

    article {
        overflow: hidden;
        padding: 0 $gutter 20px;

        > .inside {
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: 0 15px 10px;
            border: 1px solid $border-color;
        }

        header, footer {
            flex-grow: 0;
        }

        main {
            flex-grow: 1;
        }

        figure {
            position: relative;
            float: left;
            width: 132px;
            margin: -1px 0 40px 0;
            padding: 14px;
            text-align: center;

            &:before {
                position: absolute;
                display: block;
                left: 0;
                bottom: -20px;
                width: 66px;
                content: "";
                border-left: 66px solid #000;
                border-bottom: 20px solid transparent;
            }

            &:after {
                position: absolute;
                display: block;
                right: 0;
                bottom: -20px;
                width: 66px;
                content: "";
                border-right: 66px solid #000;
                border-bottom: 20px solid transparent;
            }

            &.hotel {
                background: #f1d88b;

                &:before, &:after {
                    border-left-color: #f1d88b;
                    border-right-color: #f1d88b;
                }
            }

            &.cruise {
                background: #6db3f6;

                &:before, &:after {
                    border-left-color: #6db3f6;
                    border-right-color: #6db3f6;
                }
            }

            &.language {
                background: #8ae5db;

                &:before, &:after {
                    border-left-color: #8ae5db;
                    border-right-color: #8ae5db;
                }
            }

            &.rail {
                background: #9aaeac;

                &:before, &:after {
                    border-left-color: #9aaeac;
                    border-right-color: #9aaeac;
                }
            }

            &.flight {
                background: #cee9ef;

                &:before, &:after {
                    border-left-color: #cee9ef;
                    border-right-color: #cee9ef;
                }
            }

            img {
                width: 53px;
                margin: 0 auto 10px;
            }

            figcaption {
                display: block;
                text-transform: uppercase;
                color: #fff;
                font-size: 14px;
                font-weight: $font-bold;
                line-height: 1.2;
                text-align: center;
            }
        }

        .date {
            position: relative;
            margin-top: 30px;
            margin-left: 148px;
            padding-left: 25px;

            &:before {
                position: absolute;
                top: 0;
                left: 0;
                width: 18px;
                height: 18px;
                background: url(../../images/counteroffer-calendar.svg) center center no-repeat;
                background-size: contain;
                content: "";
            }

            span {
                display: block;
            }

            time {
                color: inherit;
                font-size: inherit;
            }
        }

        .target {
            position: relative;
            margin-top: 1em;
            margin-left: 148px;
            padding-left: 25px;

            &:before {
                position: absolute;
                top: 0;
                left: 0;
                width: 18px;
                height: 18px;
                background: url(../../images/counteroffer-target.svg) center center no-repeat;
                background-size: contain;
                content: "";
            }
        }

        h1 {
            clear: both;
            color: $primary-color;
            font-size: 20px;
            font-weight: $font-medium;
        }

        .teaser {
            display: block;
            margin: 3px 0;
            font-size: 15px;
            line-height: 1.6;
        }

        footer {
            margin: 30px 0 0;
            font-size: 15px;
            line-height: 1.3;
        }
    }

    @include media(small) {
        article {
            width: span(1 of 2);
        }
    }

    @include media(medium) {
        .filters {
            display: flex;
            padding: 20px;

            .filter {
                width: span(1 of 3);
                padding: 15px;

                select {
                    overflow: hidden;
                }
            }
        }

        article {
            padding-bottom: 29px;
        }
    }

    @include media(xlarge) {
        article {
            width: span(1 of 3);
        }
    }
}
