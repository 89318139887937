@import "defaults";

.share-buttons {
    li {
        display: inline-block;
        margin: 0 5px;

        &.print {
            display: none;
        }
    }

    a {
        display: block;
        width: 32px;
        height: 32px;
        background: no-repeat center center;
        font-size: inherit;
        text-indent: -999em;

        //noinspection CssOptimizeSimilarProperties
        &.bluesky {
            background-color: $bluesky-color;
            background-image: url(../images/share-bluesky.svg) ;
            background-size: 18px 15px;
        }

        //noinspection CssOptimizeSimilarProperties
        &.facebook {
            background-color: $facebook-color;
            background-image:  url(../images/share-facebook.svg);
            background-size: 8px 15px;
        }

        //noinspection CssOptimizeSimilarProperties
        &.linkedin {
            background-color: $linkedin-color;
            background-image:  url(../images/share-linkedin.svg);
            background-size: 15px 15px;
        }

        //noinspection CssOptimizeSimilarProperties
        &.whatsapp {
            background-color: $whatsapp-color;
            background-image:  url(../images/share-whatsapp.svg);
            background-size: 16px 16px;
        }

        //noinspection CssOptimizeSimilarProperties
        &.comments {
            background-color: #ababab;
            background-image:  url(../images/share-comments.svg);
            background-size: 19px 17px;
        }

        //noinspection CssOptimizeSimilarProperties
        &.email {
            background-color: #737373;
            background-image:  url(../images/share-email.svg);
            background-size: 17px 17px;
        }

        //noinspection CssOptimizeSimilarProperties
        &.print {
            background-color: #3b3b3b;
            background-image:  url(../images/share-print.svg);
            background-size: 17px 17px;
        }
    }

    @include media(medium) {
        li {
            &.print {
                display: inline-block;
            }

            &.whatsapp {
                display: none;
            }
        }

        a {
            width: 86px;
            padding: 10px 10px 0 30px;
        }
    }
}

.ce_social_links {
    text-align: center;
    margin: 2em 0;

    + .ce_text {
        margin-top: -1em;
    }
}
