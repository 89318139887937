@import "defaults";

address, time {
    display: inline-block;
    font-style: normal;
    font-size: 14px;
    line-height: 1.2em;
    color: $level2-text-color;
}

h1 {
    .tag {
        margin-right: 5px;
        color: $primary-color;
        font-weight: $font-bold;

        .mod_news_sponsored & {
            display: none;
        }

        .advertising & {
            position: relative;
            margin-right: 2px;
            padding: 2px 8px;
            color: #fff;
            font-size: 13px;
            text-transform: uppercase;
            background-color: #a8a8b0;

            .layout-reader & + span {
                display: block;
            }
        }

        .image_primary.advertising & {
            top: -0.5em;
        }

        .image_secondary.advertising & {
            top: -2px;
        }
    }

    .layout-reader .advertising & {
        margin-top: 0;
    }
}

.news_address {
    address {
        margin-right: 15px;
    }

    address + time:before {
        padding-right: 8px;
        margin-left: -10px;
        content: "-";
    }
}

.pagination {
    padding: 0 $gutter;

    p {
        float: left;
    }

    ul {
        float: right;
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    li {
        float: left;
        margin: 1em 5px 0;
        padding: 0;
    }
}

.message.error {
    display: block;
    max-width: 620px;
    margin: 20px auto;
    padding: 25px;
    border: 10px solid #fc0d1b;
    font-size: 18px;

    .help {
        display: block;
        margin-top: 1em;
        font-size: 15px;
    }
}

@import "blocks";
@import "modules/news_home";
@import "modules/news_section";
