@import "../defaults";

.mod_newsletter {
    margin-bottom: 45px;
    padding: 0 $gutter;

    .layout-homepage & {
        padding: 0 $gutter-homepage;
    }

    .mod_article > & {
        padding: 0;
    }

    .mod_article.no-padding > & {
        padding: 0 $gutter;
    }

    > .inside {
        padding: 25px;
        color: #fff;
        text-align: center;
        background: #2489d8;
    }

    &__headline {
        font-size: 20px;
        line-height: 24px;
        font-weight: $font-bold;
    }

    &__description {
        font-size: 14px;
        line-height: 18px;
        margin: 8px 0;

        &--desktop {
            display: none;
        }
    }

    &__link {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 48px;
        margin-top: 22px;
        color: #fff;
        font-size: 15px;
        line-height: 48px;
        font-weight: $font-bold;
        text-transform: uppercase;
        white-space: nowrap;
        background: rgb(2,206,189);
        background: linear-gradient(90deg, rgba(2,206,189,1) 0%, rgba(20,216,121,1) 100%);
        border-radius: 2px;

        &:hover {
            text-decoration: none;
        }

        svg {
            display: block;
            margin-left: 15px;
            width: 18px;
            height: 18px;
            fill: #fff;
        }
    }

    @include media(medium) {
        #main & {
            > .inside {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 15px 30px;
                text-align: left;
            }

            &__headline {
                font-size: 30px;
                line-height: 1;
            }

            &__description {
                &--mobile {
                    display: none;
                }
                &--desktop {
                    display: block;
                }
            }

            &__link {
                margin: 0 0 0 20px;
                padding: 0 15px;
            }
        }
    }
}

.ce_mailchimp {
    .image_container {
        display: none;
    }

    form {
        margin: 40px -10px 0;
    }

    input {
        display: block;
        width: 100%;
        height: 34px;
    }

    .widget-text input {
        margin-bottom: 10px;
        padding: 0 12px;
        border: 1px solid $border-color;
    }

    .widget-submit input {
        @include on-primary-background;
        border: none;
        font-size: 13px;
        font-weight: $font-medium;
        line-height: 32px;
        text-align: center;
        text-transform: uppercase;
        border-radius: 2px;
    }

    @include media(medium) {
        .widget-text {
            @include span(4 of 6);
        }

        .widget-submit {
            @include span(2 of 6);
        }
    }

    @include media(large) {
        .image_container {
            display: block;
        }

        form {
            margin-left: 150px;
        }
    }
}
