.mod_news_special_list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 $gutter * -1 45px;

    @include media(small) {
        article {
            width: span(1 of 2);
        }
    }

    @include media(xlarge) {
        article {
            width: span(1 of 3);
        }
    }
}
