@import "defaults";

#main {
    .mod_article {
        padding: 0 $gutter;
    }

    .mod_article.no-padding {
        padding: 0 !important;
    }

    .mod_article > h1 {
        @extend .page-headline;
        margin: 0;

        + .ce_text {
            margin-bottom: 20px;
            font-size: 36px;
            line-height: 1.2em;
            text-align: center;
            text-transform: uppercase;

            strong {
                font-weight: $font-bold;
            }

            p {
                margin: 0;
            }
        }
    }

    .mod_article > h2,
    .mod_contest h2 {
        font-size: 30px;
        line-height: 1.1em;
        margin-top: 1.3em;
    }

    .mod_article > .ce_text > h3,
    .mod_contest h3 {
        margin-top: 1.5em;
        font-weight: $font-bold;
    }
}

.layout-content #main .mod_article + .mod_article {
    clear: both;
    overflow: hidden;
    margin-top: 3.5em;
    margin-left: 10px;
    margin-right: 10px;
    padding: 3.5em 0 0;
    border-top: 1px solid $primary-color;

    h2 {
        margin-top: -17px;
    }

    .ad_zone {
        > div {
            margin-bottom: 0 !important;
        }
    }
}

.layout-content #main .mod_article:last-of-type {
    margin-bottom: 45px;

    &:after {
        content: "";
        display: table;
        clear: both
    }
}

#contact-social-links {
    @include span(1 of 8);
    margin: 1em 0 0 span(1 of 8);

    ul {
        display: block;
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        padding: 8px 0;
    }

    a {
        display: block;
        width: 32px;
        height: 33px;
        margin: 10px auto;
        padding: 8px;
        text-align: center;

        &.facebook {
            background-color: $facebook-color;
        }

        &.bluesky {
            display: flex;
            background-color: $bluesky-color;
        }

        &.linkedin {
            background-color: $linkedin-color;
        }
    }

    svg {
        fill: $body-color;
        max-height: 100%;
    }

    + .ce_text {
        @include span(6 of 8);
    }
}

.mod_news_search {

    form {
        margin: 0 $gutter 24px;
        line-height: 37px;

        &:after {
            content: "";
            display: table;
            clear: both;
        }

        legend {
            float: left;

            + * {
                clear: left;
            }
        }
    }

    .widget-search {
        margin-bottom: 17px;
    }

    .filter {
        margin-bottom: 13px;
        padding: 10px 10px 18px;
        line-height: 34px;
        background: #f6f6f6;
    }

    .filter-date legend,
    .filter-section label {
        font-weight: $font-bold;
        text-transform: uppercase;
    }

    .filter-date {
        .radio_container span {
            display: inline-block;
            width: 98px;
        }

        .widget-submit {
            margin-top: 10px;

            button:disabled {
                background: #f6f6f6;
                border: 1px solid #bcbcc4;
                color: #bcbcc4;
                cursor: not-allowed;
            }
        }
    }

    .filter-section {
        margin-top: 15px;

        label {
            display: block;
        }
    }

    .sort-by {
        float: right;
        visibility: hidden;
    }

    @include media(small) {
        .filter-date .radio_container span {
            width: auto;
            margin-right: 20px;
        }

        .filter-date .widget,
        .filter-date label,
        .filter-date input.text {
            display: inline-block;
        }

        .filter-date input.text {
            width: 100px;
            margin: 10px 8px 0 2px;
        }

        .sort-by {
            label,
            select {
                float: left;
                margin-left: 10px;
            }
        }
    }

    @include media(medium) {
        .filter-date .radio_container span {
            margin-right: 12px;
        }
    }

    @include media(large) {
        .widget-search {
            margin-left: 130px;
            margin-right: 130px;
        }

        .filter {
            position: relative;
            padding-left: 130px;
        }

        .filter-date legend,
        .filter-section label {
            position: absolute;
            left: 10px;
            margin-top: 2px;
        }

        .filter-section {
            max-width: 300px;
        }
    }

    @include media(xlarge) {
        .filter-date .radio_container span {
            margin-right: 20px;
        }
    }

    @include media(max) {
        .widget-search {
            margin-left: 150px;
            margin-right: 150px;
        }

        .filter {
            position: relative;
            padding-left: 150px;
        }

        .filter-date .radio_container span {
            margin-right: 35px;
        }

        .filter-date input.text {
            width: 150px;
            margin-right: 15px;
            margin-left: 5px;
        }

        .filter-section {
            max-width: 413px;
        }
    }

    #search-results {
        min-height: 300px;

        &.loading {
            height: 100px;
            background: url('../images/ajax-loader.gif') center center no-repeat;

            > * {
                display: none;
            }
        }

        em {
            font-style: normal;
            background-color: #ccebfe;
        }
    }
}

iframe.twitter-tweet {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
