@import "defaults";
@import url("https://use.typekit.net/hqa6ijx.css?v=2");

html {
    box-sizing: border-box;
    scroll-behavior: smooth;
}
*, *:before, *:after {
    box-sizing: inherit;
}
@media screen and (prefers-reduced-motion: reduce) {
    html {
        scroll-behavior: auto;
    }
}

body, form, figure, blockquote {
    margin: 0;
    padding: 0
}

img, svg, iframe {
    display: block;
    max-width: 100%;
    border: 0;
}

img, svg {
    height: auto;
}

.image_container img {
    width: 100%;

    &.adaptive {
        width: auto;
    }
}

fieldset {
    border: none;
    margin: 0;
    padding: 0;
}

hr {
    height: 1px;
    margin: 3.5em 0;
    border: none;
    background-color: $border-color;

    & + .ce_text {
        margin-top: -1em;
    }

    @include media(large) {
        margin-left: 20%;
        margin-right: 20%;
    }
}

header, footer, main, nav, section, aside, article, figure, figcaption {
    display: block
}

html, body, form, fieldset, p, div, h1, h2, h3, h4, h5, h6 {
    -webkit-text-size-adjust: none;
}

body, input, textarea, select {
    font: $font-regular 16px/1.4em $font-sans-serif;
    color: $default-text-color;
}

select, input, textarea {
    font-size: 99%;

    @include placeholder() {
        color: $level4-text-color;
        text-indent: 0;
    }
}

strong {
    font-weight: $font-medium;
}

a {
    color: $primary-color;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

h1, h2, h3, h4, h5, h6 {
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    margin: 0;
}

figcaption {
    padding: 5px 0;
    font-size: 14px;
    color: $level2-text-color;
    text-align: left;
    letter-spacing:0.02em;
}

#container, #main, #right, .inside {
    position: relative
}

#container:after,
.centered-section:after {
    content: "";
    display: table;
    clear: both
}

.layout-two-cols #container,
.layout-one-col #main,
.layout-homepage #main,
.centered-section {
    max-width: 520px;
    margin: 0 auto;
}

.layout-two-cols #right {
    display: none;
}

@include media(medium) {
    .layout-two-cols #container,
    .layout-one-col #main,
    .centered-section {
        max-width: $max-width;
    }

    .layout-homepage #main {
        max-width: $max-width-homepage;
    }

    .layout-two-cols {
        #container {
            display: flex;
        }

        #main {
            width: span(8 of 12);
            flex-grow: 1;
        }

        #right {
            display: block;
            width: 320px;
            flex-shrink: 0;
        }
    }

    .layout-two-cols #right > .inside > *,
    .layout-reader .right > .inside {
        padding-left: $gutter;
        padding-right: $gutter;
    }
}

.desktop-only {
    display: none !important;
}
@include media(xlarge) {
    .desktop-only {
        display: inherit !important;
    }

    .mobile-only {
        display: none !important;
    }
}

.overflow {
    overflow: visible;
}

.clear, #clear {
    height: .1px;
    font-size: .1px;
    line-height: .1px;
    clear: both
}

.invisible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.list_items ol, .list_items ul, .list_items li,
.list_inline ol, .list_inline ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.list_inline li {
    @extend .list_items;
    display: inline;
}

@import "content_blocks";
