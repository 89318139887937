@import "defaults";

.image_primary, .image_secondary, .image_featured, .image_none,
.image_search, .image_video_list, .image_video_home,
.image_pinboard_list, .image_event_list {
    padding: 0 $gutter 20px;
    @extend .news_address;

    .layout-homepage & {
        padding-left: $gutter-homepage;
        padding-right: $gutter-homepage;
    }

    .inside {
        overflow: hidden;
    }

    .image_container {
        background: url('../images/ajax-loader.gif') no-repeat center center;
    }

    h1 {
        hyphens: auto;
    }

    a {
        color: $default-text-color;
        text-decoration: none;
    }

    .teaser {
        display: none;

        a.section {
            color: $primary-color;
            font-weight: $font-bold;
        }
    }

    @include media(medium) {
        padding-bottom: 29px;
    }
}

.image_primary .inside,
.image_secondary .inside {
    border-top: 1px dotted $border-color;
    padding-top: 40px;
}

.image_primary.has-podcast,
.image_secondary.has-podcast,
.image_featured.has-podcast,
.image_search.has-podcast {
    .image_container {
        position: relative;

        &:after {
            position: absolute;
            left: 12px;
            bottom: 12px;
            width: 42px;
            height: 40px;
            border: 1px solid #fff;
            background: rgba(0,0,0,.2) url('../images/podcast.svg') 10px 8px/18px 21px no-repeat;
            content: "";
        }
    }
}

.image_primary,
.image_secondary,
.image_none,
.image_video_list {
    h1 {
        margin: 20px 0 8px;
        font-size: 36px;
        line-height: 1em;
    }
}

.image_primary {
    text-align: center;

    h1 {
        font-size: 54px;
        line-height: 0.8888em;
    }

    .teaser {
        display: block;
    }
}

.image_secondary,
.image_video_list {
    @include media(medium) {
        @include span(6 of 12);

        .teaser {
            display: block;
        }
    }

    @include media(xlarge) {
        width: span(4 of 12);
    }
}

.image_video_home {
    h1 {
        margin: 20px 0 8px;
        font-size: 24px;
        line-height: 1em;
    }

    @include media(medium) {
        @include span(6 of 12);
    }
}

.image_featured {
    .text_container {
        position: relative;
        top: -40px;
        margin: 0 30px;
        padding: 15px 20px;
        background: #fff;
        text-align: center;
    }

    h1 {
        margin: 0 0 8px;
        font-size: 24px;
        line-height: 1em;
        text-transform: uppercase;
    }

    @include media(medium) {
        @include span(6 of 12);
    }

    @include media(xlarge) {
        width: span(4 of 12);
    }
}

.image_search {
    h1 {
        font-size: 18px;
        line-height: 1.1em;
        margin-bottom: 5px;
    }
}

.related .image_search {
    h1 {
        margin: 10px 0 5px;
    }
}

#search-results .image_search {
    padding: 0 $gutter;

    .inside {
        overflow: hidden;
        padding: 10px 0 13px;
        border-bottom: 1px solid $primary-color;
    }

    a {
        text-decoration: none;

        h1 {
            margin-bottom: .5em;
            font-size: 20px;
            color: $default-text-color;
        }
    }

    p {
        margin: 0.5em 0 0;
    }

    .image_container {
        display: none;
    }

    .image_container, .text_container {
        margin-top: 5px;
    }

    .teaser {
        display: block;
    }

    @include media(xlarge) {
        .image_container {
            display: block;
            float: left;
            width: 210px;
        }

        .inside > a + .text_container {
            overflow: hidden;
            padding-left: 10px;
        }
    }

    @include media(max) {
        .text_container {
            padding-right: span(1 of 16);
        }
    }
}
