@import "defaults";

p,
.ce_hyperlink,
.ce_youtube_preview {
    margin: 1em 0;
}

.ce_image,
.ce_youtube,
.ce_vimeo,
.ce_oembed_podigee {
    margin: 2em 0;

    + .ce_text {
        margin-top: -1em;
    }
}

.ce_text {
    overflow: hidden;
    margin-bottom: -1em;

    &.teaser {
        max-width: 700px;
        margin: 1em auto 2em;
        font: italic 22px/1.33em $font-serif;
        text-align: center;
        letter-spacing: 0.01em;
    }

    .image_container {
        margin: 2em 0 1em;

        &.float_below {
            margin-top: 1em;
            margin-bottom: 2em;
        }

        h2 + & {
            margin-top: 1.5em;
        }
    }

    @include media(medium) {
        .image_container {
            &.float_left {
                float: left;
                max-width: 33%;
                margin-right: 1em;
            }

            &.float_right {
                float: right;
                max-width: 33%;
                margin-left: 1em;
            }

            & + p {
                margin-top: 2em;
            }

            &.float_above + p {
                margin-top: 1em;
            }

            h2 + & + p {
                margin-top: 1.5em;
            }
        }

        p {
            overflow: auto;
        }
    }
}

.ce_hyperlink a {
    padding-left: 1.2em;
    background: url("../images/hyperlink-text.svg") left center no-repeat;
    background-size: 0.8em 0.8em;
    color: $default-text-color;

    &.hyperlink_btn {
        background-image: none;
    }
}

.page-headline {
    position: relative;
    margin: 0 $gutter;
    padding: 13px 24px;
    font-size: 18px;
    font-weight: $font-bold;
    line-height: 22px;
    text-transform: uppercase;

    a {
        color: $default-text-color;
        text-decoration: none;
    }

    &:before {
        position: absolute;
        left: 2px;
        top: 18px;
        width: 12px;
        height: 12px;
        background-color: $primary-color;
        content: "";
    }

    @include media(medium) {
        padding: 0 36px 28px;

        &:before {
            top: 5px;
            left: 12px;
        }
    }
}

.primary-header {
    position: relative;
    margin-top: 5px;
    border-top: 1px solid $border-color;

    &:before {
        content: "";
        position: absolute;
        top: -5px;
        left: 0;
        display: block;
        height: 5px;
        width: 40px;
        background: $primary-color;
    }

    h2 {
        margin: 10px 0 15px;
        font-size: 20px;
        line-height: 24px;
        font-weight: $font-bold;

        a {
            color: inherit;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

.responsive-video,
.ce_youtube,
.ce_vimeo {
    position: relative;
    padding-bottom: 100% / 16 * 9;
    height: 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.ce_gallery {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    margin: 0 $gutter * -1;

    .image_container {
        flex-grow: 1;
        margin: 0 $gutter;

        img {
            width: auto;
        }
    }
}
