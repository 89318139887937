@import "../defaults";

.mod_news_hometop {
    margin-top: 25px;
    margin-bottom: 20px;

    .image_secondary,
    .image_none {
        .teaser {
            display: block;
        }
    }

    .ad_zone {
        margin-top: 20px;
    }

    .prio2 .image_primary {
        text-align: left;

        h1 {
            font-size: 36px;
            line-height: 1em;
        }
    }

    .prio1 .image_primary,
    .prio1 .image_none {
        .inside {
            border-top: 0;
            padding-top: 0;
        }
    }

    @include media(medium) {
        margin-top: 38px;

        .featured {
            display: flex;

            .ad_zone {
                min-width: 300px;
            }
        }

        .prio2 {
            display: flex;
            flex-wrap: wrap;

            .image_primary {
                text-align: center;

                h1 {
                    font-size: 54px;
                    line-height: 0.8888em;
                }
            }
        }
    }

    @include media(xlarge) {
        .image_primary {
            width: span(8 of 12);
        }

        .prio1 {
            display: flex;

            .image_primary {
                width: span(8 of 12);
            }

            .featured {
                display: block;
                width: span(4 of 12);
            }

            .image_secondary {
                float: none;
                width: 100%;
            }
        }
    }
}

.layout-homepage #main > .inside > .image_primary {
    text-align: left;

    h1 {
        font-size: 36px;
        line-height: 1em;
    }

    @include media(medium) {
        width: span(6 of 12);
    }

    @include media(xlarge) {
        width: span(7 of 12);
        text-align: center;

        h1 {
            font-size: 54px;
            line-height: 0.8888em;
        }
    }
}
