@import "../defaults";

.mod_news_section {
    overflow: hidden;
    margin-bottom: 45px;

    > h2 {
        @extend .page-headline;
    }

    .image_primary .inside {
        border-top: 0;
        padding-top: 0;
    }

    > h1 {
        margin-bottom: 20px;
        font-size: 36px;
        line-height: 1.2em;
        text-align: center;
        text-transform: uppercase;
    }

    .teaser .section {
        display: none !important;
    }

    @include media(medium) {
        .image_secondary:nth-child(2n+1) {
            clear: left;
        }
    }

    @include media(xlarge) {
        .image_secondary {
            width: span(1 of 3);

            &:nth-child(2n+1) {
                clear: none;
            }

            &:nth-child(3n) {
                clear: left;
            }
        }
    }

    .ce_hyperlink {
        clear: both;
        padding-top: 1em;
        text-align: center;
        border-top: 1px solid $border-color;
    }
}

@include media(medium) {
    .layout-section #right > .inside {
        padding-top: 50px;
    }
}
