@import "defaults";

#mobile-toggle {
    position: absolute;
    display: block;
    left: 10px;
    bottom: 14px;
    width: 23px;
    height: 19px;
    text-indent: -999em;
    background: url(../images/menu-open.svg) no-repeat center center;

    .mobile-active & {
        background-image: url(../images/menu-close.svg);
    }
}

#search-toggle {
    display: none;

    @include media(medium) {
        position: absolute;
        display: block;
        left: 47px;
        bottom: -2px;
        height: 42px;
        padding: 12px 12px 14px 34px;
        font-size: 14px;
        line-height: 1.15em;
        font-weight: $font-medium;
        color: $default-text-color;
        text-transform: uppercase;
        text-decoration: none;
        background: url(../images/search.svg) no-repeat 12px 13px;
        background-size: 13px 13px;

        .search-active & {
            background-color: #eeeeee;
        }
    }
}

#search-rollover,
#mobile-rollover {
    display: none;
}

.mobile-active {
    #mobile-rollover {
        display: block;
        padding-bottom: 30px;

        @include media(medium) {
            position: absolute;
            width: 400px;
            margin-top: 2px;
            background: $body-color;
            z-index: 100;
            -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.19);
            -moz-box-shadow:    0 2px 5px 0 rgba(0, 0, 0, 0.19);
            box-shadow:         0 2px 5px 0 rgba(0, 0, 0, 0.19);
        }
    }

    #search-rollover {
        display: block;
        margin: 0 10px;
        padding: 30px 0;
        border-top: 7px solid $level1-text-color;

        form {
            position: relative;
            margin: 0 $gutter;
            padding-bottom: 7px;
            border-bottom: 1px solid $level1-text-color;
        }

        input {
            border: 0;
            background: transparent;
            -webkit-appearance: none;

            &.text {
                width: 100%;
                height: 20px;
                padding: 0 30px 0 0;
                font-size: 16px;
                line-height: 20px;
            }

            &.submit {
                position: absolute;
                top: -10px;
                right: 0;
                width: 20px;
                height: 32px;
                text-indent: -999em;
                background: url(../images/search.svg) no-repeat center center;
                background-size: 100%;
            }
        }

        @include media(medium) {
            display: none !important;
        }
    }
}

.search-active #search-rollover {
    position: absolute;
    display: block;
    width: 100%;
    margin: 2px 0 0;
    z-index: 100;

    form {
        margin: 0 10px;
        padding: 19px 20px 17px 100px;
        border: 0;
        border-bottom: 1px solid #b4b4b4;
        background: #eeeeee;
    }

    input {
        border: 0;
        background: transparent;
        -webkit-appearance: none;

        &.text {
            width: 100%;
            height: 29px;
            padding: 0;
            font-size: 24px;
            line-height: 29px;
            outline: none;
        }

        &.submit {
            position: absolute;
            top: 20px;
            left: 58px;
            width: 29px;
            height: 29px;
            text-indent: -999em;
            background: url(../images/search.svg) no-repeat center center;
            background-size: 100%;
        }
    }
}

#mobile-navigation {
    padding: 0 $gutter 10px;

    li {
        border-top: 1px solid $border-color;
        padding-top: 10px;

        &.submenu {
            border: 0;
        }
    }

    a {
        display: block;
        padding: 0 6px;
        font-size: 18px;
        line-height: 38px;
        color: $level1-text-color;
        text-decoration: none;
        text-transform: uppercase;

        &.submenu {
            color: $primary-color;
            font-weight: $font-bold;
        }

        &:hover, &.active {
            @include on-primary-background;
        }
    }

    .level_2 a {
        font-weight: $font-bold;
    }
}

#mobile-social {
    overflow: hidden;
    margin: 0 10px 10px;
    padding: 22px 0;
    border-top: 3px solid $border-color;
    border-bottom: 3px solid $border-color;

    li {
        float: left;
        margin-right: 25px;
    }

    a {
        display: block;
        width: 19px;
        height: 19px;

        &.facebook {
            width: 10px;
        }

        &.bluesky {
            width: 20px;
            margin-top: 1px;
        }
    }

    svg {
        fill: $level1-text-color;
        width: 100%;
        height: 100%;
    }
}

#mobile-services {
    margin: 0 10px;

    a {
        font-size: 16px;
        line-height: 36px;
        color: $level1-text-color;

        &.active, &.trail {
            color: $primary-color;
        }
    }
}

#mobile-legal {
    margin: 10px 10px 0;
    padding-top: 10px;
    border-top: 3px solid $border-color;

    a {
        font-size: 14px;
        line-height: 36px;
        color: $level2-text-color;

        &.active, &.trail {
            color: $primary-color;
        }
    }
}

#main-navigation {
    display: none;
    overflow: visible;
    text-align: center;
    border-bottom: 1px solid $default-text-color;

    &.specials {
        display: block;
    }

    ul {
        @extend .centered-section;
    }

    li {
        display: none;

        &.special {
            display: inline;
        }
    }

    a {
        display: inline-block;
        padding: 14px 10px 10px;
        margin: 0 6px -2px;
        font-size: 16px;
        line-height: 1em;
        font-weight: $font-medium;
        color: $default-text-color;
        text-transform: uppercase;
        text-decoration: none;
        border-bottom: 3px solid transparent;

        &:hover, &.active {
            border-bottom: 3px solid #000;
        }
    }

    @include media(medium) {
        display: block;

        li {
            display: inline;
        }
    }
}

#news-rollover {
    position: absolute;
    display: none;
    width: 100%;
    z-index: 100;

    margin-top: 2px;
    padding: 27px 0 35px;
    background: $body-color;
    background: rgba(255,255,255,0.95);
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.19);
    -moz-box-shadow:    0 2px 5px 0 rgba(0, 0, 0, 0.19);
    box-shadow:         0 2px 5px 0 rgba(0, 0, 0, 0.19);

    .news-active & {
        display: block;
    }

    .mod_navigation {
        @include span(3 of 12);

        li {
            border-bottom: 1px solid #dcdadd;
        }

        a {
            display: block;
            padding: 0 6px;
            font-size: 14px;
            line-height: 29px;
            color: $level1-text-color;
            text-decoration: none;
            text-transform: uppercase;

            &:hover, &.active, &.trail {
                @include on-primary-background;
            }
        }
    }
}

.mod_news_navigation {
    @extend .news_address;
    overflow: hidden;

    a {
        color: inherit;
        text-decoration: none;
    }

    a.section {
        color: $primary-color;
        font-weight: $font-bold;
    }

    .image_container {
        display: none;
    }

    .text_container {
        overflow: hidden;
    }

    .top {
        article {
            width: auto;
            float: none;

            > .inside {
                border: none;
            }
        }
    }

    .more {
        clear: both;

        > h2 {
            padding: 15px 10px 8px;
            font-size: 13px;
            color: $primary-color;
            text-transform: uppercase;
        }

        article {
            float: left;
            width: span(3 of 9);
            padding-bottom: 0;
        }
    }

    @include media(large) {
        .top {
            .image_container {
                display: block;
                float: left;
                width: 50%;
                max-width: 420px;
                margin-right: 20px;
            }
        }

        .more {
            .image_container {
                display: block;
                width: 40%;
            }
        }
    }
}
