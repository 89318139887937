.mod_pinboard {
    margin-bottom: 45px;

    > p {
        margin: 0 0 20px;
        font-size: 36px;
        line-height: 1.2em;
        text-align: center;
        text-transform: uppercase;
    }

    .ad_zone.ad_empty {
        display: none !important;
    }

    .result {
        margin: 2em 0;
        text-align: center;
    }

    .filters {
        background: #f7f7f7;
        margin: 2em 0 3em;
        padding: 10px 0;

        .filter {
            position: relative;
            padding: 5px 15px;

            .filter-trigger {
                overflow: hidden;
                padding: 5px 10px 5px 27px;
                text-overflow: ellipsis;
                white-space: nowrap;
                background: #fff;
                border: 1px solid #dddde1;
                border-radius: 2px;
                background-position: 8px center;
                background-repeat: no-repeat;
                background-size: 12px 12px;
                cursor: pointer;

                &--regions {
                    background-image: url('../../images/pinboard-regions.svg');
                }
                &--stars {
                    background-image: url('../../images/pinboard-stars.svg');
                }
                &--categories {
                    background-image: url('../../images/pinboard-categories.svg');
                }
            }

            .filter-dropdown {
                display: none;
                position: absolute;
                flex-wrap: wrap;
                left: 0;
                top: calc(100% + 8px);
                margin: 0 15px;
                padding: 20px 30px;
                background: $body-color;
                -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.19);
                -moz-box-shadow:    0 2px 5px 0 rgba(0, 0, 0, 0.19);
                box-shadow:         0 2px 5px 0 rgba(0, 0, 0, 0.19);
                z-index: 1;

                &:before {
                    position: absolute;
                    top: -8px;
                    left: 30px;
                    width: 16px;
                    height: 16px;
                    background: #fff;
                    transform: rotate(45deg);
                    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.19);
                    -moz-box-shadow:    0 2px 5px 0 rgba(0, 0, 0, 0.19);
                    box-shadow:         0 2px 5px 0 rgba(0, 0, 0, 0.19);
                    content: "";
                    z-index: -1;
                }

                &:after {
                    position: absolute;
                    top: 0;
                    left: 20px;
                    width: 40px;
                    height: 20px;
                    background: #fff;
                    content: "";
                    z-index: 5;
                }

                label {
                    display: block;
                    position: relative;
                    cursor: pointer;
                    width: 100%;
                    padding: 0 12px 0 26px;
                    margin: 2px -12px;

                    > input {
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }

                .filter-group {
                    width: 100%;
                    margin: 4px 0;

                    .filter-group-title {
                        position: relative;
                        padding-right: 40px;
                        font-weight: $font-medium;
                        border-bottom: 1px solid $border-color;
                    }

                    .filter-group-content {
                        display: none;
                        flex-wrap: wrap;
                        padding: 10px 0 10px 20px;
                    }

                    .filter-group-trigger {
                        display: block;
                        width: 24px;
                        height: 20px;
                        position: absolute;
                        top: 0;
                        right: 0;
                        cursor: pointer;
                        background: #fff url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSItMTk3IDQ3OS45IDI0IDEyIj48c3R5bGU+LnN0MHtmaWxsOiNjZGNkY2R9PC9zdHlsZT48cGF0aCBjbGFzcz0ic3QwIiBkPSJNLTE4OSA0ODMuOWgtOGw0IDR6Ii8+PC9zdmc+) center center no-repeat;
                        background-size: 24px 12px;
                        z-index: 1;
                    }

                    &.active {
                        .filter-group-trigger {
                            transform: rotateX(180deg);
                        }

                        .filter-group-content {
                            display: flex;
                        }
                    }
                }

                .widget-submit {
                    width: 100%;
                    margin: 30px 0 0;
                    text-align: right;
                }
            }

            &.stars .filter-dropdown {
                max-width: 250px;
            }

            &.active .filter-dropdown {
                display: flex;
            }

            @include media(medium) {
                &.regions {
                    .filter-dropdown {
                        width: 750px;
                        max-width: 90vw;
                        padding-left: 18px;
                        padding-right: 18px;
                    }

                    .filter-group {
                        width: 50%;
                        padding: 0 12px;
                    }
                }
            }
        }
    }

    .items {
        display: flex;
        flex-wrap: wrap;
        margin: 0 $gutter * -1;
    }

    article {
        width: 100%;
        margin-bottom: 1em;

        > .inside {
            overflow: visible;
            border: 1px solid $border-color;
        }

        .image_container {
            margin: -1px -1px 0;
        }

        .text_container {
            padding: 15px 20px;
        }

        h1 {
            color: $primary-color;
            font-size: 20px;
            font-weight: $font-medium;
        }

        .location {
            margin: 3px 0;
            font-size: 14px;
            line-height: 1.3;
        }

        .teaser {
            display: block;
            margin: 3px 0;
            font-size: 15px;
            line-height: 1.6;
        }

        ul.categories,
        ul.categories li {
            margin: 0;
            padding: 0;
            list-style-type: none;
            display: inline-block;
        }

        .categories img {
            width: auto;
            height: 12px;
        }
    }

    @include media(small) {
        article {
            width: span(1 of 2);
        }
    }

    @include media(medium) {
        .filters {
            display: flex;
            padding: 20px;

            .filter {
                width: span(1 of 3);
                padding: 15px;

                select {
                    overflow: hidden;
                }
            }
        }
    }

    @include media(xlarge) {
        article {
            width: span(1 of 3);
        }
    }
}

.pinboard {
    &__popup {
        width: 900px;
        max-width: 90%;
        margin: 0 auto;
        padding: 20px;
        background: #fff;
        text-align: left;
    }

    &__inside {
        max-width: 660px;
        margin: 0 auto;

        .image_container {
            background: url('../../images/ajax-loader.gif') no-repeat center center;
        }
    }

    &__categories {
        float: right;
        padding: 0;
        margin: 15px 0 0;
        list-style-type: none;

        li {
            margin: 0;
            padding: 0;
            display: inline-block;
        }

        img {
            height: 18px;
            width: auto
        }
    }

    &__headline {
        margin: 15px 0 0;
        color: $primary-color;
        font-size: 20px;
        font-weight: $font-medium;
        line-height: 1.1;
    }

    &__location {
        margin: 3px 0;
        font-size: 14px;
        line-height: 1.3;
    }

    &__teaser {
        margin: 25px 0 15px;
        font-size: 36px;
        line-height: 1em;
        text-align: center;
        text-transform: uppercase;
    }

    &__description {
        margin: 15px 0;
        text-align: center;
    }

    &__link {
        display: block;
        width: 250px;
        margin: 30px auto 0;
        color: #fff;
        font-size: 13px;
        font-weight: $font-medium;
        line-height: 35px;
        text-transform: uppercase;
        text-align: center;
        background: $primary-color;
        border-radius: 1px;

        &:hover {
            text-decoration: none;
        }
    }

    @include media(xlarge) {
        &__popup {
            max-width: 100%;
            padding: 60px 20px 45px;
        }

        &__teaser {
            margin-left: 60px;
            margin-right: 60px;
        }
    }
}

body.pinboard-popup {
    .mobx-overlay {
        background: rgba(68, 132, 197, 0.95);
    }

    .mobx-top-bar {
        background: none;
    }

    .mobx-html-inner {
        -webkit-overflow-scrolling: touch;
    }
}
