@import "defaults";

.cookiebar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    color: #fff;
    font-size: 11px;
    line-height: 16px;
    background: rgba(96,56,19,.9);

    > .inside {
        max-width: 1200px;
        margin: 0 auto;
        padding: $gutter;
    }

    p {
        margin: 0;
    }

    a {
        color: #d29560;
        text-decoration: underline;
    }

    button {
        float: right;
        height: 27px;
        margin: 15px;
        padding: 0 40px;
        color: #603813;
        font-size: 12px;
        font-weight: $font-bold;
        text-transform: uppercase;
        white-space: nowrap;
        background: #d29560;
        border: none;
        border-radius: 2px;
        cursor: pointer;
    }

    @include media(medium) {
        font-size: 13px;

        &:before {
            position: absolute;
            top: -140px;
            left: calc(50% - 87px);
            width: 174px;
            height: 130px;
            background: url("../images/cookie.svg") 0 0 no-repeat;
            background-size: contain;
            content: "";
        }

        > .inside {
            display: flex;
            flex-flow: row-reverse;
            align-items: flex-end;
            padding: 20px 30px;
        }

        p {
            width: 100%;
        }

        button {
            position: relative;
            margin: 0 40px;
            height: 36px;
            padding-right: 20px;
            font-size: 13px;
            border-radius: 2px 0 0 2px;

            &:after {
                position: absolute;
                top: -26px;
                right: -59px;
                width: 59px;
                height: 62px;
                background: url("../images/cookie-button.svg") 0 0 no-repeat;
                background-size: contain;
                content: "";
            }
        }
    }
}
