.layout-reader main > article.travelnews-tv {
    header {
        h1 {
            margin-top: 18px;
        }

        figure {
            margin: 28px 0;
        }
    }
}

.layout-reader main > article {
    .video-preview {
        position: relative;
        cursor: pointer;

        &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 105px;
            height: 100px;
            margin: -53px 0 0 -50px;
            border: 3px solid #fff;
            background: url('../../images/play.svg') center center no-repeat;
            background-size: 45px 53px;
            content: "";
        }
    }

    @include media(medium) {
        .video-preview {
            &:after {
                width: 210px;
                height: 200px;
                margin: -105px 0 0 -100px;
                background-size: 90px 105px;
            }
        }
    }
}

.mod_video_list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 $gutter * -1 45px;

    .image_video_list {
        margin-bottom: 1em;
    }

    > .ce_hyperlink {
        width: 100%;
        margin-left: $gutter;
        margin-right: $gutter;
        padding-top: 1em;
        text-align: center;
        border-top: 1px solid $border-color;
    }

    @include media(small) {
        .image_video_list {
            width: span(1 of 2);
        }
    }

    @include media(xlarge) {
        .image_video_list {
            width: span(1 of 3);
        }
    }
}

.mod_video_list,
.mod_video_home {
    h2 {
        a {
            color: $default-text-color;
            text-decoration: none;
        }
    }
}

.image_video_list.has-video .image_container,
.image_video_home.has-video .image_container {
    position: relative;

    &:after {
        position: absolute;
        left: 12px;
        bottom: 12px;
        width: 42px;
        height: 40px;
        border: 1px solid #fff;
        background: rgba(0,0,0,.2) url('../../images/play.svg') 12px 8px/18px 21px no-repeat;
        content: "";
    }
}
