
$max-width: 1200px;
$max-width-homepage: 1220px;
$gutter: 10px;
$gutter-homepage: 20px;

$body-color: #fff;
$primary-color: #4484c5;
$error-color: #ffcccc;
$border-color: #cbcbcb;
$default-text-color: #171715;
$level1-text-color: #3f3f3e;
$level2-text-color: #797979;
$level3-text-color: #a9a9a9;
$level4-text-color: #bcbcc4;

$facebook-color: #2d609b;
$bluesky-color: #1185fe;
$linkedin-color: #007ab8;
$whatsapp-color: #25d366;

$font-sans-serif: franklin-gothic-urw, sans-serif;
$font-serif: tinos, serif;
$font-regular: 300;     /* Light */
$font-medium: 500;      /* Medium */
$font-bold: 700;        /* Demi */

@mixin media($size, $block: null) {
    $sizes: (
        max: "(min-width: #{$max-width})",
        xlarge: "(min-width: 960px)",
        large: "(min-width: 860px)",
        medium: "(min-width: 720px)",
        small: "(min-width: 480px)",
    );

    @if (map-has_key($sizes, $size)) {
        $size: map-get($sizes, $size);
    } @else if(type_of($size) == 'number') {
        $size: "(min-width: #{$size}px)";
    }

    @media #{unquote($size)} {
        @if ($block) {
            #{$block} {
                @content;
            }
        } @else {
            @content;
        }
    }
}

@mixin span($size, $homepage: false) {
    width: span($size);
    @if ($homepage) {
        padding-left: $gutter-homepage;
        padding-right: $gutter-homepage;
    } @else {
        float: left;
        padding-left: $gutter;
        padding-right: $gutter;
    }
}

@function span($size) {
    @if (type-of($size) == list) {
        $_of: index($size, of);
        $_return: 1 / nth($size, $_of + 1) * nth($size, $_of - 1);

        @return percentage($_return);
    }

    @return $size;
}

@mixin on-primary-background {
    background-color: $primary-color;
    color: #fff;
}

@mixin clearfix($prop: after) {
    &:#{$prop} {
        display: table;
        clear: both;
        content: "";
    }
}

@mixin placeholder {
    &::-webkit-input-placeholder {@content}
    &:-moz-placeholder           {@content}
    &::-moz-placeholder          {@content}
    &:-ms-input-placeholder      {@content}
}
