@import "../defaults";

.mod_event_list {

    .empty {
        margin-bottom: 45px;
    }

    .items {
        display: flex;
        flex-wrap: wrap;
        margin: 0 $gutter * -1;
    }

    article {
        width: 100%;
        margin-bottom: 1em;

        > .inside {
            overflow: visible;
            height: 100%;
            border: 1px solid $border-color;
        }

        .image_container {
            margin: -1px -1px 0;
        }

        .text_container {
            padding: 15px 20px;
        }

        .event_type {
            @extend address;
            text-transform: uppercase;

            &:after {
                padding: 0 6px 0 10px;
                content: "\007C";
            }
        }

        h1 {
            color: $primary-color;
            font-size: 20px;
            font-weight: $font-medium;
        }

        p {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            max-height: 4.2em;
            overflow: hidden;
        }
    }

    @include media(small) {
        article {
            width: span(1 of 2);
        }
    }

    @include media(xlarge) {
        article {
            width: span(1 of 3);
        }
    }

    + .ce_hyperlink {
        width: 100%;
        margin-bottom: 45px;
        padding-top: 1em;
        text-align: center;
        border-top: 1px solid $border-color;
    }
}

.mod_event_reader {

    h2 {
        @extend .page-headline;
        margin: 0;
    }

    header {
        text-align: center;

        .event_type {
            @extend address;
            text-transform: uppercase;

            &:after {
                padding: 0 6px 0 10px;
                content: "\007C";
            }
        }

        h1 {
            margin: 15px auto 5px;
            font-size: 40px;
            line-height: 1em;
        }

        figure {
            margin: 28px 0;
            border-top: 3px solid #4484c5;
        }
    }

    main {
        margin: 2em 0;
    }

    .meta {
        position: relative;
        margin: 3em 0;
        padding: 10px 20px 40px;
        background: #f6f6f6;

        &:after {
            content: "";
            position: absolute;
            right: 16px;
            top: -13px;
            width: 52px;
            height: 52px;
            background: url("../../images/event-pin.svg") 0 0 no-repeat;
            background-size: contain;
        }

        h3 {
            @extend .page-headline;
            margin: 0;
        }

        .details {
            strong {
                display: block;
            }

            .item {
                margin-bottom: .5em;
            }

            a {
                margin-top: 1em;
            }
        }
    }

    @include media(medium) {
        .meta {
            padding: 20px 20px 40px 10px;

            .details {
                margin-left: 10px;
            }
        }
    }

    @include media(large) {
        header {
            h1 {
                margin: 25px auto 15px;
                font-size: 54px;
            }
        }

        .meta {
            .details {
                display: flex;
            }

            .group {
                width: 33.33333333%;
                padding-right: 10px;

                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }
}
