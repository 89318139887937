.mod_job_list {
    .item {
        p {
            margin: 0;
        }

        .company {
            color: $level4-text-color;
        }

        .headline {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: .2em 0 .4em;
        }

        h3 {
            padding-right: 20px;
            font-size: 20px;
            line-height: 22px;
            font-weight: $font-bold;

            a {
                color: inherit;

                &:hover {
                    text-decoration: none;
                }
            }

            small {
                font-size: inherit;
                font-weight: $font-regular;
            }
        }

        .share-buttons {
            margin: 3px 0 0 -3px;
            padding: 0;
            line-height: 18px;

            li {
                margin: 0 3px;
            }

            a {
                width: 18px;
                height: 18px;
                padding: 0;
                border-radius: 2px;
                background-size: 14px;

                &.facebook {
                    background-size: 7px;
                }
            }
        }

        .description {
            a.more {
                margin-left: 5px;

                &:before {
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    margin-right: 3px;
                    background: url("../../images/hyperlink-blank.svg") 0 0 no-repeat;
                    content: "";
                }
            }
        }

        .location {
            margin-top: .4em;
            font-size: 14px;
            color: $level4-text-color;
        }
    }
}
