@import "defaults";

#wrapper {
    position: relative;
}

#header {
    width: 100%;
    background: $body-color;
    border-top: 4px solid $primary-color;
    border-bottom: 2px solid $default-text-color;
    z-index: 100;

    .actions {
        position: relative;
        padding: 21px 0 14px;
    }

    @include media(small) {
        .actions {
            padding-top: 17px;
        }
    }
}

//noinspection CssOptimizeSimilarProperties
#logo {
    display: block;
    width: 213px;
    height: 42px;
    margin: 0 auto;
    background: url(../images/logo.svg) no-repeat left top;
    background-size: 100% 100%;
    text-decoration: none;

    @include media(medium) {
        .layout-homepage & {
            width: 301px;
            height: 61px;
        }
    }
}

@import "navigation";

#container {
    padding-top: 15px;

    @include media(medium) {
        padding-top: 30px;
    }
}

#back-to-top {
    height: 24px;
    margin-top: 15px;
    border-top: 1px solid $default-text-color;

    .inside {
        position: relative;
    }

    a {
        position: absolute;
        right: 10%;
        top: -0.6em;
        padding: 0 10px 0 30px;
        background: $body-color url(../images/backtotop.svg) no-repeat 10px center;
        background-size: 14px 14px;
        font-size: 14px;
        font-weight: $font-medium;
        color: $default-text-color;
        text-decoration: none;
    }
}

#footer {
    background-color: $default-text-color;

    .inside, h6 {
        color: $level3-text-color;
        font-size: 14px;
        line-height: 1em;
    }

    a {
        color: $level3-text-color;
    }

    h6 {
        display: none;
    }

    h6, li {
        margin-bottom: 10px;
    }

    #footer-logo {
        float: left;
        padding: 30px 15px;

        > a.logo {
            display: block;
            width: 140px;
            height: 21px;
            background: url("../images/logo-footer.svg") 0 0 no-repeat;
            text-decoration: none !important;
        }

        /* Social Media */
        li {
            float: left;
            margin: 16px 14px 0 4px;

            a {
                display: block;
                width: 14px;
                height: 14px;

                &.facebook {
                    width: 7px;
                }

                &.bluesky {
                    width: 15px;
                    margin-top: 1px;
                }
            }
        }

        svg {
            fill: #fff;
            width: 100%;
            height: 100%;
        }
    }

    #footer-news, #footer-services {
        display: none;
    }

    #footer-about {
        float: right;
        padding: 35px 20px;
        text-align: right;
    }

    @include media(medium) {
        h6 {
            display: block;
            font-weight: $font-bold;
            color: $body-color;
            text-transform: uppercase;
        }

        #footer-logo {
            @include span(3 of 12);
        }

        #footer-news {
            float: left;
            width: span(4 of 12);
            display: block;
            padding: 30px 10px;

            ul {
                clear: both;
            }

            li {
                float: left;
                width: 50%;
            }
        }

        #footer-services,
        #footer-about {
            float: left;
            width: span(2 of 12);
            display: block;
            padding: 30px 10px;
            text-align: left;
        }
    }
}

#legal {
    padding: 25px 0;
    text-align: center;
    color: $level2-text-color;
    font-size: 13px;
    line-height: 1.57em;

    li {
        display: inline;
        margin: 0 15px;
    }

    a {
        color: $default-text-color;

        &.active {
            color: $primary-color;
        }
    }
}
