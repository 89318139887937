@import "../defaults";

.layout-reader main > article {

    > h2 {
        @extend .page-headline;
    }

    header {
        @extend .news_address;
        margin: 0 $gutter 35px;
        font-size: 18px;
        line-height: 1.3;
        text-align: center;

        .image_container {
            background: url('../../images/ajax-loader.gif') no-repeat 50% 40%; // not center because of video preview icon
        }

        h1 {
            margin: 15px auto 5px;
            font-size: 40px;
            line-height: 1em;
        }

        time:after {
            content: none;
        }

        .share-buttons {
            margin-top: 2em;
        }

        .teaser {
            max-width: 700px;
            margin: 1em auto 2em;
        }

        // TODO: deprecated
        figure {
            position: relative;
            border-top: 3px solid $primary-color;

            a.gallery {
                position: relative;
                display: block;

                //noinspection CssOptimizeSimilarProperties
                div {
                    position: absolute;
                    left: 18px;
                    bottom: 18px;
                    height: 56px;
                    width: 57px;
                    background: #000 url(../../images/gallery-icon.svg) 13px 12px no-repeat;
                    background-color: rgba(0,0,0,0.6);
                    background-size: 31px 28px;
                    font: italic 12px/999em $font-serif;
                    letter-spacing: 0.01em;
                    color: $body-color;
                    text-align: left;

                    strong {
                        font: $font-bold 13px $font-sans-serif;
                        text-transform: uppercase;
                    }
                }
            }
        }

        @include media(medium) {
            figure {
                a.gallery {
                    div {
                        width: auto;
                        padding: 9px 18px 9px 56px;
                        line-height: 17px;
                    }
                }
            }

            time, address {
                display: none;
            }

            .teaser {
                width: span(10 of 12);
            }
        }

        @include media(large) {
            h1 {
                margin: 25px auto 15px;
                font-size: 54px;
            }

            time, address {
                display: inline-block;
            }
        }

        @include media(xlarge) {
            time, address {
                display: none;
            }

            h1 {
                width: span(10 of 12);
            }

            .teaser {
                width: span(8 of 12);
            }
        }
    }

    .meta {
        display: none;
    }

    .main {
        position:relative;
        width: 100%;
        margin-bottom: 45px;

        section {
            clear: both;
            padding: 0 $gutter;
        }

        section + .ad_zone.ad_loaded {
            margin-top: 45px;
            margin-bottom: -45px;
        }

        section.related {
            padding: 0;
            border: 0;
            margin-bottom: 0;

            > .inside {
                margin: 0 $gutter -29px;
                padding-left: 0;
                padding-right: 0;
                border-top: 1px solid #dddde1
            }
        }

        .ce_additional,
        .ce_quick_survey {
            position: relative;
            overflow: visible;

            > .inside {
                margin: 1em 0;
                padding: 20px 20px 4px;
                background: #f6f6f6;
                overflow-wrap: anywhere;

                &:before {
                    position: absolute;
                    top: -13px;
                    right: 5%;
                    width: 50px;
                    height: 50px;
                    background:  url("../../images/clip.svg") left top  no-repeat;
                    content: "";
                }
            }

            h3 {
                position: relative;
                margin: 0;
                padding: 0 60px 0 24px;
                font-size: inherit;
                font-weight: $font-bold;
                text-transform: uppercase;

                &:before {
                    position: absolute;
                    left: 0;
                    top: 4px;
                    width: 12px;
                    height: 12px;
                    background-color: $primary-color;
                    content: "";
                }
            }
        }

        .ce_additional {
            + .ce_text {
                overflow: visible;

                &:before {
                    display: table;
                    content: "";
                }

                p {
                    overflow: visible;
                }
            }
        }

        .ce_embedded_tweet,
        .ce_embedded_instagram_post,
        .ce_oembed_facebook {
            width: 500px;
            min-width: 220px;
            max-width: 100%;
            margin: 2em auto;
        }

        .ce_lightbox_gallery {
            width: span(10 of 12);
            margin: 2em span(1 of 12);

            a {
                position: relative;
                display: block;

                div {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    background: rgba(0,0,0,0.6);

                    p {
                        margin: 0;
                        padding-top: 72px;
                        font: italic 22px/999em $font-serif;
                        letter-spacing: 0.01em;
                        color: $body-color;
                        line-height: 17px;
                        text-align: center;
                        background: url('../../images/gallery-icon.svg') 50% 0 no-repeat;
                        background-size: 62px 53px;
                    }

                    strong {
                        display: block;
                        font: $font-bold 24px $font-sans-serif;
                        text-transform: uppercase;
                    }
                }
            }
        }

        .ce_quick_survey {
            margin-top: 2em;
            color: $level1-text-color;

            h2 {
                margin: 20px 0;
                text-align: center;
            }

            .options {
                ol {
                    margin: 0;
                    padding: 0;
                    list-style-type: none;
                }

                li {
                    margin: $gutter 0;
                }

                input {
                    position: absolute;
                    visibility: hidden;
                }

                label {
                    display: block;
                    padding: 5px 10px;
                    background: #fff;
                    border-radius: 5px;
                    text-transform: uppercase;
                    cursor: pointer;
                }

                input:checked + label {
                    background: #e1e1e5;
                }
            }

            button {
                display: block;
                width: 50%;
                margin: 30px auto;
                cursor: pointer;
            }

            .doughnut {
                position: relative;
                width: 270px;
                height: 270px;
                margin: 0 auto 2em;

                strong {
                    position: absolute;
                    top: 110px;
                    left: 50%;
                    font-size: 72px;
                    transform: translate(-50%, 0);
                    white-space: nowrap;
                }

                p {
                    position: absolute;
                    width: 150px;
                    top: 145px;
                    left: 50%;
                    transform: translate(-50%, 0);
                    font-size: 14px;
                    line-height: 1em;
                    text-align: center;
                }
            }

            .results {
                margin: 0 0 2em 28px;

                li {
                    margin: 0 0 20px 38px;
                    text-transform: uppercase;
                    font-size: 15px;
                    line-height: 1em;

                    strong {
                        float: left;
                        margin-left: -38px;

                        &:before {
                            position: absolute;
                            display: block;
                            width: 20px;
                            height: 20px;
                            margin: -2px 0 0 -28px;
                            border-radius: 10px;
                            content: '';
                        }
                    }

                    &:nth-child(1) strong:before {
                        background-color: #2d6da5;
                    }

                    &:nth-child(2) strong:before {
                        background-color: #3a7cba;
                    }

                    &:nth-child(3) strong:before {
                        background-color: #4f8ecb;
                    }

                    &:nth-child(4) strong:before {
                        background-color: #aecbeb;
                    }

                    &:nth-child(5) strong:before {
                        background-color: #b4d4f1;
                    }

                    &:nth-child(6) strong:before {
                        background-color: #c8e1f7;
                    }

                    &:nth-child(7) strong:before {
                        background-color: #ccebfc;
                    }

                    &:nth-child(8) strong:before {
                        background-color: #a5a2a3;
                    }

                    &:nth-child(9) strong:before {
                        background-color: #716e72;
                    }
                }
            }
        }

        h2 {
            font-size: 30px;
            line-height: 1.1em;
            margin-top: 1.3em;
        }

        h3 {
            margin-top: 1.5em;
            font-size: 20px;
            font-weight: $font-medium;
        }

        blockquote {
            margin-left: span(1 of 8);
            font: italic 30px/36px $font-serif;
            letter-spacing: 0.01em;

            + .ce_text,
            + .ce_additional,
            + .ce_additional + .ce_text {
                margin-top: -1em;
            }
        }

        p.author {
            font-style: italic;
        }

        footer {
            margin: 2em $gutter 0;
            padding: 15px;
            background-color: #f6f6f6;
            font-size: 15px;

            h4 {
                font-weight: $font-medium;
                text-transform: uppercase;
                margin-bottom: .5em;
            }

            ul, li {
                margin: 0;
                padding: 0;
                list-style-type: none;
            }

            a {
                display: block;
                margin-left: 10px;
                padding-left: 1.2em;
                background: url("../../images/hyperlink-text.svg") 0 0.35em no-repeat;
                background-size: 0.8em 0.8em;
                color: $default-text-color;
            }
        }
    }

    .right {
        padding-top: 1em;

        > .inside > *:not(.show-mobile) {
            display: none;
        }
    }

    @include media(medium) {
        .content {
            display: flex;
            flex-wrap: wrap;
        }

        .meta {
            display: block;
            width: 180px;
            margin-bottom: 45px;

            .inside {
                margin-top: 20px;
                padding: 0 $gutter;
                font-size: 14px;
                line-height: 1.25em;

                div {
                    padding: 10px 0 20px;
                    border-top: 1px solid $border-color;
                }
            }

            h3 {
                margin-bottom: 0.5em;
                font-weight: $font-bold;
                text-transform: uppercase;
            }

            p {
                margin: 0;
            }

            .author {
                .image_container {
                    width: 95px;
                    margin: 0 auto 11px;
                }

                img {
                    border-radius: 50%;
                }
            }
        }

        .main {
            width: calc(100% - 180px);

            .ce_additional {
                float: right;
                width: 50%;
                margin: 0 span(1 of 16)*-1 0 $gutter;
                padding: 0 $gutter;

                > .inside {
                    margin-bottom: 2em;
                }
            }

            .ce_quick_survey {
                padding: 0 20px;

                .results {
                    margin: 0 10px 2em 48px;
                }
            }

            section,
            footer {
                padding-right: span(1 of 16);
            }

            .ce_image, .ce_youtube, .ce_vimeo, .ce_oembed_podigee {
                margin-left: span(1 of 8)*-1;
            }

            .ce_youtube, .ce_vimeo {
                padding-bottom: (100% + span(1 of 8)) / 16 * 9;
            }
        }

        .right {
            width: 100%;
        }
    }

    @include media(large) {
        .content {
            flex-wrap: nowrap;
        }

        .meta {
            display: none;
        }

        .main {
            width: calc(100% - 320px);

            .ce_image, .ce_youtube, .ce_vimeo, .ce_oembed_podigee {
                margin-left: 0;
            }

            .ce_youtube, .ce_vimeo {
                padding-bottom: 100% / 16 * 9;
            }
        }

        .right {
            width: 320px;

            > .inside > *:not(.show-mobile) {
                display: inherit;
            }
        }
    }

    @include media(xlarge) {
        .meta {
            display: block;
        }

        .main {
            width: calc(100% - 180px - 320px);

            .ce_quick_survey {
                h2 {
                    margin: 20px 30px;
                }

                .options {
                    margin: 0 45px;
                }
            }

            .ce_image, .ce_youtube, .ce_vimeo, .ce_oembed_podigee {
                margin-left: span(1 of 8)*-1;
            }

            .ce_youtube, .ce_vimeo {
                padding-bottom: (100% + span(1 of 8)) / 16 * 9;
            }
        }
    }

    @include media(max) {
        .main {
            .ce_quick_survey {
                .doughnut {
                    float: left;
                    margin: 0 0 2em 10px;
                }

                .results {
                    margin: 80px 10px 2em 340px;
                }
            }
        }
    }
}

.layout-reader section.related {
    clear: both;
    margin-top: 120px;
    margin-bottom: 60px;
    border-top: 1px solid $border-color;

    > .inside {
        margin-bottom: -29px;
        padding: 25px $gutter 0;
    }

    h3 {
        margin: 5px 80px 20px 0;
        font: $font-bold 20px $font-sans-serif;
        text-transform: uppercase;
    }

    .slider {
        margin: 0 -10px;

        .inside {
            position: relative;
        }
    }

    button {
        float: right;
        width: 28px;
        height: 34px;
        padding: 0 7px;
        text-indent: -999em;
        background: none;
        border: none;
        cursor: pointer;

        &.slick-next {
            margin-right: 24px;
        }

        svg {
            width: 14px;
            height: 14px;
            fill: $level1-text-color;
        }

        &.slick-disabled {
            cursor: default;

            svg {
                fill: $level4-text-color;
            }
        }
    }
}

.layout-reader section.comments {
    clear: both;
    margin-top: 15px;
    margin-bottom: 45px;
    border-top: 3px solid $primary-color;
    background-color: #f2f6fb;

    .inside {
        max-width: 900px;
        padding: 50px $gutter;
    }
}
