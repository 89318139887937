@import "defaults";

.ad_zone {
    box-sizing: content-box;
    clear: both;
    display: flex;
    justify-content: center;
    align-self: flex-start;
    margin: 0 auto;
    padding: 0 $gutter;
    max-width: 100%;

    .mobx-html & {
        padding-top: 20px;
    }

    > div {
        position: relative;
        box-sizing: content-box;
        margin-top: -4px !important;
        padding: 3px 0 !important;
        margin-bottom: 45px !important;
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;

        &:before {
            position: absolute;
            left: 0;
            top: -15px;
            color: $level3-text-color;
            font-size: 11px;
            line-height: 16px;
            content: "Werbung";

            html[lang="en"] & {
                content: "Ad"
            }
        }

        .layout-homepage & {
            margin-bottom: 70px !important;
        }
    }

    /* Fixes overflow for Google AdSense */
    ins {
        display: block !important;
        max-width: 100%;
    }
}

.ad_margin {
    > div {
        margin-top: 55px !important;
    }
}

#banner-header {
    background-color: $level1-text-color;

    .ad_zone > div {
        padding: 7px 0 !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        border: none !important;

        &:before {
            content: none;
        }
    }
}
